<template>
  <vx-card class="w-1/3" title="Labels Edition" no-shadow>
    <vs-list>
      <div v-for="(label, index) in initialLabels" :key="index">
        <div v-show="label.shouldBeDeleted != true" class="flex mt-4">
          <vs-input
            @change="label.isDirty = true"
            class="mr-8"
            label-placeholder="Name"
            v-model="label.name"
          ></vs-input>
          <color-picker @change="label.isDirty = true" class="mt-6 mr-6" v-model="label.color"></color-picker>
          <feather-icon
            icon="TrashIcon"
            svgClasses="w-8 h-8 hover:text-danger stroke-current"
            class="ml-2 mt-5 light-gray"
            @click="removeLabelFromTheLocalList(label)"
          />
        </div>
      </div>
    </vs-list>
    <vs-button class="ml-2 mt-6" @click="addLabel()" type="border">New Label</vs-button>

    <!-- Save & Reset Button -->
    <div class="mt-10 flex flex-wrap items-center justify-end">
      <vs-button v-on:click="save()" class="ml-auto mt-2"> Save </vs-button>
      <vs-button v-on:click="cancel()" class="ml-4 mt-2" type="border" color="warning">Cancel</vs-button>
    </div>
  </vx-card>
</template>

<script>
import router from '@/router'

export default {
  inject: ['$validator'],
  components: {},
  data() {
    return {
      initialLabels: [],
      userPid: this.$store.state.AppActiveUser.pid,
      labelsThatNeedToBeRemoved: [],
    }
  },
  computed: {},
  async created() {
    await this.$store.dispatch('hoverlay/getUserLabels')

    this.initialLabels = []
    if (this.$store.state.hoverlay) {
      // this.initialLabels = this.$store.state.hoverlay.labels
      this.$store.state.hoverlay.labels.forEach(label => {
        if (label.user_pid == this.userPid) this.initialLabels.push(label)
      })
    }
  },
  methods: {
    removeLabelFromTheLocalList(label) {
      const index = this.initialLabels.indexOf(label)
      if (index > -1) {
        // only splice array when item is found
        this.initialLabels.splice(index, 1) // 2nd parameter means remove one item only
      }
      this.labelsThatNeedToBeRemoved.push(label)
    },
    addLabel() {
      this.initialLabels.push({ user_pid: this.userPid, color: '#999999', isDirty: true })
    },
    cancel() {
      router.go(-1)
    },
    async save() {
      console.log(this.initialLabels)
      // Create and update labels
      for (const label of this.initialLabels) {
        console.log(label)
        if (label.isDirty == true && (label.pid == null || label.pid == undefined)) {
          console.log('DIRTY NO PID')
          var newlyCreatedLabel = await this.$store.dispatch('hoverlay/postLabel', label)
          label.pid = newlyCreatedLabel.pid
        } else if (label.isDirty == true) {
          console.log('DIRTY PID')
          await this.$store.dispatch('hoverlay/putLabel', label)
        }
        label.isDirty = false
      }
      // Delete labels
      for (const label of this.labelsThatNeedToBeRemoved) {
        if (label.pid) await this.$store.dispatch('hoverlay/removeLabel', label.pid)
      }
      this.labelsThatNeedToBeRemoved = []

      this.$vs.notify({
        text: 'Space updated',
        color: 'success',
        icon: 'check_box',
      })
    },
  },
}
</script>

<style lang="scss">
.gray {
  color: #626262;
  font-size: 0.7rem;
}
</style>
