<!-- =========================================================================================
  File Name: Cutout.vue
  Description: Form to edit the atributes of an image cutout hoverpack
========================================================================================== -->

<template>
  <div>
    <vs-checkbox v-model="showAdvanced" class="mr-3 mb-2">Advanced</vs-checkbox>
    <div v-show="showAdvanced">
      <div>
        <vue-json-editor
          height="400px"
          v-model="json"
          :show-btns="false"
          :expandedOnStart="true"
          @has-error="onJsonError"
          @json-change="onJsonChange"
        ></vue-json-editor>
      </div>
    </div>
    <br />
  </div>
</template>

<script>
import vueJsonEditor from 'vue-json-editor'
import * as Utils from '@/assets/js/utils/utils.js'

export default {
  components: {
    vueJsonEditor,
  },
  props: {
    initialPlacement: {},
    layer: {},
  },
  data() {
    return {
      showAdvanced: false,
      json: null,
    }
  },
  created: function () {
    if (Utils.isJsonString(this.layer.settings)) this.json = JSON.parse(this.layer.settings)
    else this.json = this.layer.settings
  },
  watch: {
    layer: {
      handler: function (updatedValue) {
        this.$emit('set layer settings', updatedValue)
        if (Utils.isJsonString(this.layer.settings)) this.json = JSON.parse(this.layer.settings)
        else this.json = this.layer.settings
      },
      deep: true,
    },
  },
  methods: {
    onJsonError() {
      // console.log('ERROR')
    },
    onJsonChange(updatedValue) {
      this.layer.settings = JSON.stringify(updatedValue, null, 2)
    },
  },
}
</script>

<style lang="scss">
.margin-top {
  margin-top: 25px;
}
</style>
