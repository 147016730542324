<template>
  <vx-card class="w-1/3" no-shadow>
    <!-- Img Row -->
    <div class="flex flex-wrap items-center mb-base">
      <vs-avatar :src="getCloudfrontUrlFromS3(activeUserInfo.small_photo)" size="70px" class="mr-4 mb-4" />
      <div>
        <input type="file" class="hidden" ref="uploadImgInput" @change="updateCurrImg" accept="image/*" />
        <vs-button class="mr-4 sm:mb-0 mb-2" @click="$refs.uploadImgInput.click()">{{
          this.$t('ProfileChannelPicture')
        }}</vs-button>

        <!-- <vs-button class="mr-4 sm:mb-0 mb-2">Upload photo</vs-button> -->
        <!-- <vs-button v-on:click="deleteUserPhoto()" type="border" color="danger">Remove</vs-button> -->
        <!-- Max size of 800kB -->
        <p class="text-sm mt-2">{{ this.$t('ProfileAllow') }}</p>
      </div>
    </div>

    <!-- Info -->
    <div class="mb-base">
      <vs-input
        class="w-full"
        name="username"
        :label-placeholder="this.$t('ProfileChannelName')"
        v-validate="{ required: true, regex: /^[a-zA-Z0-9-]+$/, min: 4 }"
        v-model="username"
      ></vs-input>
      <span v-if="errors.has('username')" class="text-danger text-sm mb-2"
        >Username must contains alphabetic characters, numbers, dashes or underscores. (Minimum 4 characters)</span
      >
    </div>
    <vs-input class="w-full mb-base" label-placeholder="Owner's email" v-model="email"></vs-input>
    <vs-input class="w-full" label-placeholder="Description" v-model="description"></vs-input>
    <div class="mt-3"></div>
    <label for="" class="placeholder">Preferred system of measurement</label>
    <v-select :clearable="false" v-model="measurement" label="text" class="mt-2 w-full" :options="measurementOptions" />
    <br />
    <label v-if="unlisted == false" class="gray"
      >Your channel does not show in search results, but can be access with its link</label
    >
    <label v-else class="gray">Anybody can find your channel</label>
    <vs-switch class="mb-base" color="success" v-model="unlisted">
      <span slot="on">Public</span>
      <span slot="off">Unlisted</span>
    </vs-switch>

    <form-layer-settings v-if="isLayerSettingsEnable" :layer="layerInfo"> </form-layer-settings>

    <vs-alert :active="isVerified" color="danger" icon-pack="feather" icon="icon-info">
      <span>{{ this.$t('ProfileVerified') }}</span>
    </vs-alert>
    <br />

    <!-- Save & Reset Button -->
    <div class="flex flex-wrap items-center justify-end">
      <vs-button :disabled="errors.items.length > 0" v-on:click="saveUserAndLayerInfoConfirm()" class="ml-auto mt-2">{{
        this.$t('ProfileSave')
      }}</vs-button>
      <vs-button v-on:click="resetUserInfo()" class="ml-4 mt-2" type="border" color="warning">{{
        this.$t('ProFileReset')
      }}</vs-button>
    </div>
  </vx-card>
</template>

<script>
import FormLayerSettings from '@/views/hoverlay/forms/templates/layer/FormLayerSettings.vue'
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import router from '@/router'
import vSelect from 'vue-select'

export default {
  inject: ['$validator'],
  components: {
    FormLayerSettings,
    vSelect,
  },
  data() {
    return {
      username: this.$store.state.AppActiveUser.username,
      email: this.$store.state.AppActiveUser.email,
      state: this.$store.state.AppActiveUser.state,
      measurement: this.$store.state.AppActiveUser.measurement,
      activeLayerChangeConfirm: false,
      isLayerSettingsEnable: HoverlayUtils.isLayerSettingsEnable(),
      getLayerPlanName: HoverlayUtils.getLayerPlanName(),
      layerInfo: HoverlayUtils.getLayerInfo(),
      description: HoverlayUtils.getLayerInfo().description,
      unlisted: HoverlayUtils.getLayerInfo().discoverability == 'everyone' ? true : false,
      measurementOptions: [
        {
          text: 'Metric',
          value: 'metric',
        },
        {
          text: 'Imperial',
          value: 'imperial',
        },
      ],
    }
  },
  computed: {
    activeUserInfo() {
      console.log(this.$store.state.AppActiveUser.small_photo)
      return this.$store.state.AppActiveUser
    },
    isVerified() {
      if (this.$store.state.AppActiveUser.state === 'active') {
        return false
      } else {
        return true
      }
    },
  },
  created() {
    if (this.measurement && this.measurement == 'metric') {
      this.measurement = {
        text: 'Metric',
        value: 'metric',
      }
    }
    if (this.measurement && this.measurement == 'imperial') {
      this.measurement = {
        text: 'Imperial',
        value: 'imperial',
      }
    }
  },
  methods: {
    saveUserAndLayerInfoConfirm() {
      if (this.$store.state.AppActiveUser.username !== this.username) {
        this.$vs.dialog({
          type: 'confirm',
          color: 'danger',
          title: this.$t('ProfileConfirmTitle'),
          text: this.$t('ProfileConfirm'),
          accept: this.saveUserAndLayerInfo,
          cancel: this.resetUserInfo,
        })
      } else {
        this.saveUserAndLayerInfo()
      }
    },
    getUser() {
      this.$vs.loading()
      this.$store
        .dispatch('auth/getUser')
        .then(() => {
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    updateUserPhoto(photo) {
      this.$vs.loading()
      this.$store
        .dispatch('auth/updateUserPhoto', photo)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: this.$t('ProfileChannelPictureSuccess'),
            text: this.$t('ProfileChannelPictureSuccessText'),
            color: 'success',
            icon: 'check_box',
          })

          console.log('SUCCESS')
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    deleteUserPhoto() {
      this.$vs.loading()

      this.$store
        .dispatch('auth/deleteUserPhoto')
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Icon mail',
            text: this.$t('ProfileChannelPictureDelete'),
            color: 'success',
            icon: 'check_box',
          })
          console.log('SUCCESS')
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    resetUserInfo() {
      this.username = this.$store.state.AppActiveUser.username
      this.email = this.$store.state.AppActiveUser.email
      router.go(-1)

      //this.$vs.notify({title:'Profile reset', text:'Successfull', color:'success', icon:'check_box'})
    },

    async updateLayer() {
      const layer = {
        description: this.description,
        settings: this.layerInfo.settings,
        pid: this.layerInfo.pid,
        discoverability: this.unlisted == true ? 'everyone' : 'unlisted',
      }
      await this.$store.dispatch('auth/updateLayer', layer)
    },
    async saveUserAndLayerInfo() {
      this.$vs.loading()
      try {
        await this.updateUser()
        await this.updateLayer()
        this.$vs.notify({
          title: this.$t('ProfileUpdateTitle'),
          text: this.$t('ProfileUpdate'),
          color: 'success',
          icon: 'check_box',
        })
      } catch (e) {
        console.log(e)
        this.$vs.notify({
          title: this.$t('ProfileUpdateFailTitle'),
          text: this.$t('ProfileUpdateFail'),
          color: 'danger',
          icon: 'cancel',
        })
      }
      this.$vs.loading.close()
    },
    async updateUser() {
      let user = {
        username: this.username,
        email: this.email,
      }

      if (this.measurement) user.measurement = this.measurement.value

      await this.$store.dispatch('auth/updateUser', user)
    },
    updateCurrImg(input) {
      if (input.target.files && input.target.files[0]) {
        this.updateUserPhoto(input.target.files[0])
      }
    },
  },
}
</script>

<style lang="scss">
.gray {
  color: #626262;
  font-size: 0.7rem;
}
.placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.7rem;
}
</style>
